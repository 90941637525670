var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"name","loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.plannedTrips,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.trip.route.name",fn:function(ref){
var item = ref.item;
return [(item.trip.route)?_c('div',[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayRoute(item.trip.route.id)}}},[_vm._v(_vm._s(item.trip.route.name))]),_c('v-chip',{staticClass:"ml-2",attrs:{"color":_vm.getTimeColor(item.trip.route.is_morning),"dark":""}},[_vm._v(" "+_vm._s(_vm.getTime(item.trip.route.is_morning))+" ")])],1):_c('div',[_c('v-chip',{attrs:{"color":"error","dark":""}},[_vm._v(" No Route ")])],1)]}},{key:"item.driver.name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayDriver(item.driver.id)}}},[_vm._v(_vm._s(item.driver.name))])]}},{key:"item.bus.license",fn:function(ref){
var item = ref.item;
return [(item.bus)?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.displayBus(item.bus)}}},[_vm._v(_vm._s(item.bus.license))]):_vm._e()]}},{key:"item.planned_date",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(_vm._s(_vm._f("moment")(item.planned_date,"LL")))])]}},{key:"item.planned_start_date_time",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.planned_start_date_time,"LT")))])]}},{key:"item.planned_end_date_time",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.planned_end_date_time,"LT")))])]}},{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.started_at,"LT")))])]}},{key:"item.ended_at",fn:function(ref){
var item = ref.item;
return [_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(item.ended_at,"LT")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showNotification && item.reservations_count >0)?_c('v-btn',{attrs:{"depressed":"","small":"","density":"compact","color":"info","icon":true},on:{"click":function($event){return _vm.sendNotification(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bell ")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }